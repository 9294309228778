import COLORS from 'const/colors';

const VideoPlayIcon = () => {
  return (
    <svg
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.768438 0.489006C0.435576 0.277184 0 0.516291 0 0.910837V13.0892C0 13.4837 0.435575 13.7228 0.768438 13.511L10.3371 7.42183C10.6459 7.22535 10.6459 6.77465 10.3371 6.57817L0.768438 0.489006Z"
        fill={COLORS.BRAND.PURPS}
      />
    </svg>
  );
};

export default VideoPlayIcon;
