const PlayButton = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
  >
    <path
      d="M36.001 65.9981C52.5695 65.9981 66.001 52.5666 66.001 35.998C66.001 19.4295 52.5695 5.99805 36.001 5.99805C19.4324 5.99805 6.00098 19.4295 6.00098 35.998C6.00098 52.5666 19.4324 65.9981 36.001 65.9981Z"
      fill="black"
      fillOpacity="0.4"
      stroke="#E2E2E2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.999 23.998L47.999 35.998L29.999 47.998V23.998Z"
      stroke="#F8F8F8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PlayButton;
