import COLORS from 'const/colors';

const ChevronLeft = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1667 1.66671L5.83332 10L14.1667 18.3334"
      stroke={COLORS.GRAY.G2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    />
  </svg>
);
export default ChevronLeft;
