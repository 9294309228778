export const META_STR_KEYS = [
  'title',
  'description',
  'gtm_id',
  'seo_keywords',
  'google_site_verification',
  'bing_site_verification',
  'noindex',
];

export const META_OBJ_KEYS = ['image', 'other_meta_tags'];

export const SHOULDER_PAGE_SECTIONS = {
  PRODUCT_CARDS_SLICE: 'Product Cards Slice',
};
