const PauseSvg = () => (
  <svg
    width="3"
    height="3"
    viewBox="0 0 3 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.75" width="0.5" y="0.5" height="2" fill="#D9D9D9" />
    <rect x="1.75" y="0.5" width="0.5" height="2" fill="#D9D9D9" />
  </svg>
);
export default PauseSvg;
