const BlackColorClose = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 2.66663L2.66666 13.3333"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.66666 2.66663L13.3333 13.3333"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default BlackColorClose;
