const VideoIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M26.8327 8.16797L18.666 14.0013L26.8327 19.8346V8.16797Z"
      fill="white"
    />
    <path
      d="M16.3327 5.83203H3.49935C2.21068 5.83203 1.16602 6.8767 1.16602 8.16536V19.832C1.16602 21.1207 2.21068 22.1654 3.49935 22.1654H16.3327C17.6213 22.1654 18.666 21.1207 18.666 19.832V8.16536C18.666 6.8767 17.6213 5.83203 16.3327 5.83203Z"
      fill="white"
    />
  </svg>
);
export default VideoIcon;
